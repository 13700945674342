import React, { useRef, useEffect, Fragment, useState } from 'react';
import { Backup, GetApp, Delete, LocationCity } from '@mui/icons-material';
import {
  Box,
  Fab,
  Typography,
  Card,
  Chip,
  Avatar,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Alert,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

import * as customQueries from '../graphql/custom/queries';
import { uploadData, getUrl, list, remove } from 'aws-amplify/storage';
import { generateClient } from 'aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';

import Loading from '../components/loading';

const OperatorBaseUpload = () => {
  //////////////////////////////////////////
  // Variaveis
  //////////////////////////////////////////
  const [loading, setLoading] = useState(false);
  const [userAttributes, setUserAttributes] = useState(null);

  const apiClient = generateClient();

  const styles = {
    container: {
      padding: '12px',
      marginBottom: '16px',
      marginLeft: '32px',
      marginRight: '32px',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '10px',
    },
  };

  const [currentOperator, setCurrentOperator] = useState({
    id: 0,
    trade: '',
    regions: { items: [] },
  });
  const [currentRegion, setCurrentRegion] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [uploadEnabled, setUploadEnabled] = useState(false);

  // calcula o último mes/ano disponível para selecao
  // previous month
  let lastAvailableDate = new Date(); // hoje
  lastAvailableDate.setMonth(lastAvailableDate.getMonth() - 1); // pega o mes passado

  // monta o array dos anos disponiveis
  const endYear = lastAvailableDate.getFullYear();
  const startYear = 2020;
  const yearsArray = [];
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year);
  }

  // ano e mês selecionados
  const [selectedMonth, setSelectedMonth] = useState(
    lastAvailableDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(
    lastAvailableDate.getUTCFullYear()
  );

  const inputFile = useRef(null);
  const pageRendered = useRef({ rendered: false });

  //////////////////////////////////////////
  // UseEffect Section
  //////////////////////////////////////////

  // Quando entra, pega qual o usuário autenticado
  useEffect(() => {
    updateUser();
    console.log('aaaa');
    pageRendered.current['rendered'] = true;
  }, []);

  // Quando atualiza a lista de operadores disponíveis, seleciona a primeira
  useEffect(() => {
    if (operatorList.length > 0) {
      setOperator(operatorList[0].id);
    }
    // eslint-disable-next-line
  }, [operatorList]);

  // Quando o usuário autentica (no início) ele atualiza a lista de operadores
  useEffect(() => {
    if (userAttributes !== null) {
      listOperators();
    }
    // eslint-disable-next-line
  }, [userAttributes]);

  // Se mudou a data de referência ou o operador,
  // recarrega as praças e planilhas
  useEffect(() => {
    if (currentOperator.id.length === 0) {
      return;
    }

    if (pageRendered.current['rendered'] === false) {
      return;
    }

    loadSpreadsheets();

    // verifica se deve deixar habilitado ou não o upload de planilhas
    checkUploadEnabled();

    // eslint-disable-next-line
  }, [selectedMonth, selectedYear, currentOperator]);

  //////////////////////////////////////////
  // Function Section
  //////////////////////////////////////////

  const checkUploadEnabled = function () {
    // ve se deixa habilitado ou nao os botoes de upload e delete
    if (Boolean(currentOperator.externalDisabled)) {
      setUploadEnabled(false);
    } else {
      // testa e só segue se o mes/ano selecionado for o ultimo mes/ano disponivel
      if (
        selectedMonth !== lastAvailableDate.getMonth() + 1 ||
        selectedYear.toString() !== lastAvailableDate.getFullYear().toString()
      ) {
        setUploadEnabled(false);
      } else {
        setUploadEnabled(true);
      }
    }
  };

  const handleChangeMonth = async function (aEvent) {
    setSelectedMonth(aEvent.target.value);
  };

  const handleChangeYear = async function (aEvent) {
    setSelectedYear(aEvent.target.value);
  };

  // busca o usuário autenticado no cognito
  const updateUser = async () => {
    try {
      const attributes = await fetchUserAttributes();
      setUserAttributes(attributes);
    } catch (error) {
      console.log(error);
      console.log('Não está autenticado');
    }
  };

  const handleChangeOperator = function (aEvent) {
    setOperator(aEvent.target.value);
  };

  const setOperator = async function (aId) {
    try {
      setLoading(true);

      const operatorId = aId;

      let nextToken = null;
      let operator = null;
      do {
        const result = (
          await apiClient.graphql({
            query: customQueries.getOperator,
            variables: {
              id: operatorId,
              nextToken: nextToken,
            },
          })
        ).data.getOperator;
        if (operator === null) {
          operator = Object.assign({}, result);
        } else {
          operator.regions.items = operator.regions.items.concat(
            result.regions.items
          );
        }

        nextToken = result.regions.nextToken;
      } while (nextToken !== null);

      setCurrentOperator(operator);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const listOperators = async function () {
    try {
      setLoading(true);
      const ids = JSON.parse(userAttributes.family_name);
      const ops = [];
      for (const id of ids) {
        const result = (
          await apiClient.graphql({
            query: customQueries.getOperatorShort,
            variables: {
              id: id,
            },
          })
        ).data.getOperator;
        if (result) {
          ops.push(result);
        }
      }
      setOperatorList(ops);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  function compareRegion(a, b) {
    try {
      function plain(str) {
        return str
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
      }

      if (plain(a.municipality.name) > plain(b.municipality.name)) {
        return 1;
      }

      if (plain(a.municipality.name) < plain(b.municipality.name)) {
        return -1;
      }

      return 0;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  const loadSpreadsheets = async function () {
    try {
      setLoading(true);

      const m = selectedMonth.toString().padStart(2, '0');
      const y = selectedYear.toString();
      const prefix = `public/${y}/${m}/${currentOperator.id}/`;

      let nextToken = null;
      const PAGE_SIZE = 100;
      const files = [];
      do {
        const result = await list({
          path: prefix,
          pageSize: PAGE_SIZE,
          nextToken: nextToken,
          options: {
            listAll: true,
          },
        });
        files.push(...result.items);
        nextToken = result.nextToken;
      } while (nextToken);

      const s = [];

      // todos arquivos do operador, para aquele período
      files.forEach(function (s3file) {
        const keys = s3file.path.split('/');
        const regionId = keys[4];
        const fileName = keys[5];

        s[regionId] = {
          fileName: fileName,
          lastModified: s3file.lastModified,
        };
      });
      setSheets(s);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const uploadSheet = async () => {
    try {
      setLoading(true);

      // `current` points to the mounted file input element
      inputFile.current.value = null;
      inputFile.current.onchange = () => {
        const sheetFile = inputFile.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
          const m = selectedMonth.toString().padStart(2, '0');
          const y = selectedYear.toString();
          const name = `public/${y}/${m}/${currentOperator.id}/${currentRegion.id}/${sheetFile.name}`;

          const result = await uploadData({
            path: name,
            data: e.target.result,
            options: {
              cacheControl: 'no-cache',
              onProgress: ({ transferredBytes, totalBytes }) => {
                if (totalBytes) {
                  console.log(
                    `Upload progress ${Math.round(
                      (transferredBytes / totalBytes) * 100
                    )} %`
                  );
                }
              },
            },
          }).result;
          console.log(result);

          setSuccessAlert('Arquivo enviado');
          loadSpreadsheets();
          setLoading(false);
          setCurrentRegion(null);
        };
        reader.readAsArrayBuffer(sheetFile);
      };

      inputFile.current.click();
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
      setLoading(false);
      setCurrentRegion(null);
    }
  };

  const downloadSheet = async function () {
    try {
      setLoading(true);

      // pega a chave (nome completo, com caminho) do arquivo no bucket S3
      const m = selectedMonth.toString().padStart(2, '0');
      const y = selectedYear.toString();
      const prefix = 'public/' + y + '/' + m + '/';
      const fileName = sheets[currentRegion.id].fileName;
      const key =
        prefix + currentOperator.id + '/' + currentRegion.id + '/' + fileName;

      // pega a URL de download temporário
      const getResult = await getUrl({
        path: key,
        options: {
          validateObjectExistence: false, // Check if object exists before creating a URL
          expiresIn: 1800, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
        },
      });
      const url = getResult.url;
      //      const url = URL.createObjectURL(result.Body);
      // abre a janela de download
      let dwnld = document.createElement('a');
      dwnld.setAttribute('href', url);
      dwnld.setAttribute('download', fileName);
      document.body.appendChild(dwnld);

      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: 20,
        /* whatever properties you want to give it */
      });
      dwnld.dispatchEvent(evt);
      document.body.removeChild(dwnld);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const deleteSheet = async function () {
    try {
      setLoading(true);
      setDeleteAlert(false);

      const m = selectedMonth.toString().padStart(2, '0');
      const y = selectedYear.toString();
      const prefix = `public/${y}/${m}/${currentOperator.id}/${currentRegion.id}/`;
      // function remove(key) {
      //   return new Promise(function (resolve, reject) {
      //     Storage.remove(key, { level: 'public' })
      //       .then((result) => resolve(result))
      //       .catch((error) => reject(error));
      //   });
      // }
      const files = (
        await list({
          path: prefix,
        })
      ).items;

      const promises = [];
      files.forEach(function (file) {
        // promises.push(remove(file.key));
        promises.push(
          remove({
            path: file.path,
          })
        );
      });

      const results = await Promise.allSettled(promises);
      // verifica se deu tudo certo
      for (const result of results) {
        if (result.status !== 'fulfilled') {
          throw new Error(result.reason);
        }
      }
      setSuccessAlert('Dados removidos');
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
      loadSpreadsheets();
      setCurrentRegion(null);
    }
  };

  function checkRegionEnabled(item) {
    const d = Boolean(item.disabled);
    return !d;
  }

  return (
    <Fragment>
      <Box
        sx={{
          padding: '32px',
          display: operatorList.length > 0 ? 'block' : 'none',
        }}
      >
        <Loading loading={Boolean(loading)} />

        <Typography>Carregamento de bases das praças</Typography>

        {/* Mensagem de erro */}
        <Snackbar
          open={Boolean(errorAlert)}
          autoHideDuration={2500}
          onClose={() => setErrorAlert(null)}
        >
          <Alert
            onClose={() => setErrorAlert(null)}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errorAlert}
          </Alert>
        </Snackbar>

        {/* Mensagem de sucesso */}
        <Snackbar
          open={Boolean(successAlert)}
          autoHideDuration={1500}
          onClose={() => setSuccessAlert(null)}
        >
          <Alert
            onClose={() => setSuccessAlert(null)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {successAlert}
          </Alert>
        </Snackbar>

        {/* pergunta se quer apagar planilha */}
        <Dialog open={deleteAlert} onClose={() => setDeleteAlert(false)}>
          <DialogTitle id="alert-dialog-title">
            {'Remover arquivo?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Quer realmente remover o arquivo?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteAlert(false)} autoFocus>
              Cancelar
            </Button>
            <Button onClick={() => deleteSheet()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: 'none' }}
          accept=".xls,.xlsx"
        />

        <Box sx={styles.container}>
          <FormControl variant="standard" sx={{ minWidth: '220px' }}>
            <InputLabel>Selecione a operadora</InputLabel>
            <Select
              name="layout"
              value={currentOperator.id}
              onChange={(e) => handleChangeOperator(e)}
              variant="standard"
            >
              {operatorList.map((item, idx) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.trade}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard">
            <InputLabel>Mês</InputLabel>
            <Select
              name="mes"
              value={selectedMonth}
              onChange={(e) => handleChangeMonth(e)}
              variant="standard"
            >
              {[...Array(12).keys()].map((item, idx) => (
                <MenuItem key={idx} value={idx + 1}>
                  {(idx + 1).toString().padStart(2, '0')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard">
            <InputLabel>Ano</InputLabel>
            <Select
              name="ano"
              value={selectedYear}
              onChange={(e) => handleChangeYear(e)}
              variant="standard"
            >
              {yearsArray.map((year, idx) => (
                <MenuItem key={idx} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Card sx={styles.container}>
          {currentOperator.regions.items
            .filter(checkRegionEnabled)
            .sort(compareRegion)
            .map((region, reIdx) => {
              return (
                <Chip
                  key={region.id}
                  avatar={<Avatar>{region.municipality.state}</Avatar>}
                  label={region.municipality.name}
                  sx={{
                    backgroundColor: Boolean(sheets[region.id])
                      ? 'indigo'
                      : 'lightgray',
                    color: Boolean(sheets[region.id]) ? 'white' : 'black',
                  }}
                  onClick={() => setCurrentRegion(region)}
                />
              );
            })}
        </Card>

        <Divider sx={{ marginTop: '32px' }} />

        {Boolean(currentRegion) && (
          <Drawer
            anchor="right"
            onClose={() => setCurrentRegion(null)}
            open={Boolean(currentRegion)}
            sx={{
              minWidth: '320px',
            }}
          >
            <List>
              <ListItem dense={true}>
                <ListItemIcon>
                  <LocationCity />
                </ListItemIcon>
                <ListItemText
                  primary={
                    currentRegion.municipality.name +
                    ' / ' +
                    currentRegion.municipality.state
                  }
                />
              </ListItem>
              <Divider />

              {/* Se tem planilha na praça selecionada */}
              {Boolean(currentRegion) && sheets[currentRegion.id] && (
                <Fragment>
                  <ListItem button onClick={(e) => downloadSheet()}>
                    <ListItemIcon>
                      <GetApp />
                    </ListItemIcon>

                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="subtitle2" color="textSecondary">
                          {sheets[currentRegion.id].fileName}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="caption" color="textSecondary">
                          {sheets[
                            currentRegion.id
                          ].lastModified.toLocaleString()}
                        </Typography>
                      }
                    />
                  </ListItem>

                  <Divider />

                  {uploadEnabled && (
                    <ListItem>
                      <Fab
                        size="small"
                        sx={{
                          color: 'white',
                          backgroundColor: 'red',
                        }}
                        onClick={() => setDeleteAlert(true)}
                      >
                        <Delete />
                      </Fab>
                    </ListItem>
                  )}

                  <Divider />
                </Fragment>
              )}

              {/* Se não tem planilha na praça selecionada */}
              {Boolean(currentRegion) &&
                !sheets[currentRegion.id] &&
                uploadEnabled && (
                  <ListItem>
                    <Fab
                      size="small"
                      sx={{
                        color: 'white',
                        backgroundColor: 'blue',
                      }}
                      onClick={(e) => uploadSheet()}
                    >
                      <Backup />
                    </Fab>
                  </ListItem>
                )}
            </List>
          </Drawer>
        )}
      </Box>
    </Fragment>
  );
};

export default OperatorBaseUpload;
