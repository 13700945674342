import React, { Fragment, useState, useEffect } from 'react';

import {
  Alert,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Snackbar,
} from '@mui/material';
import { ExitToApp, Error } from '@mui/icons-material';
import { Route, Routes, BrowserRouter, NavLink } from 'react-router-dom';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import OperatorBaseUpload from './containers/operatorBaseUpload';
// import OperatorBaseReports from './containers/operatorBaseReports';
import PartnerBaseReports from './containers/partnerBaseReports';
import logo from './assets/images/logo.png';
import './App.css';
import * as customQueries from './graphql/custom/queries';
import Loading from './components/loading';

// amplify
import { Amplify } from 'aws-amplify';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { post, generateClient } from 'aws-amplify/api';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify/utils';
import '@aws-amplify/ui/styles.css';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const MyRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Box />} />
      <Route path="/baseUpload" element={<OperatorBaseUpload />} />
      {/* <Route path="/operatorReport" element={<OperatorBaseReports />} /> */}
      <Route path="/partnerReport" element={<PartnerBaseReports />} />

      {/* 404 must be the last one */}
      <Route element={<Error />} />
    </Routes>
  );
};

function App({ signOut }) {
  const [loading, setLoading] = useState(true);
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [user, setUser] = useState(null); // usuário autenticado
  const [errorAlert, setErrorAlert] = useState(false);

  const [isOperator, setIsOperator] = useState(false);
  const [isPartner, setIsPartner] = useState(false);

  const apiClient = generateClient();

  // Listen to Amplify Auth events
  Hub.listen('auth', ({ payload }) => {
    if (payload.event === 'signedIn') {
      updateUser();
    }
  });

  // Quando entra, pega qual o usuário autenticado
  useEffect(() => {
    updateUser();
  }, []);

  // pega o usuário autenticado e lista, pelos IDs que estão no campo family_name,
  // quais as empresas vinculadas e vê se é de operador e/ou parceiro
  const updateUser = async () => {
    try {
      const { username } = await getCurrentUser();
      setUser(username);

      const attributes = await fetchUserAttributes();
      const ids = JSON.parse(attributes.family_name);

      // verifica se é de operador
      for (const id of ids) {
        const operator = (
          await apiClient.graphql({
            query: customQueries.getOperatorShort,
            variables: {
              id: id,
            },
          })
        ).data.getOperator;

        if (operator && Boolean(operator.disabled) === false) {
          setIsOperator(true);
          break;
        }
      }

      // verifica se é de parceiro
      for (const id of ids) {
        const partner = (
          await apiClient.graphql({
            query: customQueries.getBroadcaster,
            variables: {
              id: id,
            },
          })
        ).data.getBroadcaster;

        if (partner && Boolean(partner.disabled) === false) {
          setIsPartner(true);
          break;
        }
      }
    } catch (error) {
      console.log(error);
      setErrorAlert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Loading loading={Boolean(loading)} />

      <Dialog
        open={logoutDialog}
        onClose={() => setLogoutDialog(false)}
        fullWidth
        maxWidth="sm"
        sx={{
          minWidth: '320px',
        }}
      >
        <DialogTitle id="alert-dialog-title">{'Logout'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quer realmente sair?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setLogoutDialog(false)}
            autoFocus
          >
            Cancelar
          </Button>
          <Button variant="outlined" onClick={signOut}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <BrowserRouter>
        <AppBar sx={{ position: 'sticky' }}>
          <Toolbar
            disableGutters={false}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: 2,
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <img src={logo} alt="Neo" title="Neo" height={32} />
            </Box>

            {isOperator && (
              <NavLink to="/baseUpload">
                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: blue[500],
                  }}
                >
                  Upload de Base de Clientes
                </Button>
              </NavLink>
            )}

            {/*isOperator && (
              <NavLink to="/operatorReport">
                <Button sx={{ color: 'white' }}>
                  Acessos por serviço digital do operador
                </Button>
              </NavLink>
            )*/}

            {isPartner && (
              <NavLink to="/partnerReport">
                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: blue[500],
                  }}
                >
                  AcessoS dos serviços digitais do parceiro
                </Button>
              </NavLink>
            )}

            <Tooltip title="sair">
              <IconButton
                className="jr-fab-btn bg-transparent text-white jr-btn-fab ml-auto"
                //onClick={signOut}
                onClick={() => {
                  setLogoutDialog(true);
                }}
                sx={{
                  color: 'white',
                }}
              >
                <ExitToApp />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <MyRoutes />

        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            padding: '4px',
            textAlign: 'right',
            backgroundColor: blue,
            color: 'white',
          }}
        >
          {user && <Typography sx={{ marginLeft: '32px' }}>{user}</Typography>}
        </Box>
      </BrowserRouter>

      {/* Mensagens de erro  */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={1500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
export default withAuthenticator(App);
