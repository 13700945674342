import React, { useEffect, Fragment, useState } from 'react';
import { Download, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Tooltip,
  IconButton,
} from '@mui/material';
import { red, indigo, green, grey, blue } from '@mui/material/colors';
import { writeFile, read } from 'xlsx';
import * as customQueries from '../graphql/custom/queries';

import { post, generateClient } from 'aws-amplify/api';
import { fetchUserAttributes } from 'aws-amplify/auth';

import Loading from '../components/loading';

const PartnerBaseReports = () => {
  //////////////////////////////////////////
  // Variaveis
  //////////////////////////////////////////
  const [loading, setLoading] = useState(false);
  const [userAttributes, setUserAttributes] = useState(null);

  const styles = {
    container: {
      flexDirection: 'row',
      padding: '12px',
      marginBottom: '16px',
      marginLeft: '32px',
      marginRight: '32px',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '10px',
      alignItems: 'center',
    },
    smallContainer: {
      flexDirection: 'row',
      padding: '6px',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: '12px',
      alignItems: 'center',
      borderStyle: 'solid',
      borderColor: 'black',
      borderRadius: '6px',
      borderWidth: 'thin',
    },
    fileContainer: {
      flexDirection: 'column',
      padding: '12px',
      marginBottom: '16px',
      marginLeft: '32px',
      marginRight: '32px',
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '10px',
    },
    file: {
      '&:hover': {
        backgroundColor: '#eeeeff',
      },
    },
  };

  const [currentCompany, setCurrentCompany] = useState({
    id: 0,
    trade: '',
  });
  const [fileList, setFileList] = useState([]);

  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);

  const [companyList, setcompanyList] = useState([]);
  const apiClient = generateClient();

  // calcula o último mes/ano disponível para selecao
  // previous month
  let lastAvailableDate = new Date(); // hoje
  lastAvailableDate.setMonth(lastAvailableDate.getMonth() - 1); // pega o mes passado

  // monta o array dos anos disponiveis
  const endYear = lastAvailableDate.getFullYear();
  const startYear = 2024;
  const yearsArray = [];
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year);
  }

  // ano e mês selecionados
  const [selectedMonth, setSelectedMonth] = useState(
    lastAvailableDate.getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState(
    lastAvailableDate.getUTCFullYear()
  );

  //////////////////////////////////////////
  // UseEffect Section
  //////////////////////////////////////////

  // Quando entra, pega qual o usuário autenticado e atualiza lista de ISPs
  useEffect(() => {
    updateUser();
  }, []);

  // Quando atualiza a lista de empresas disponíveis, seleciona a primeira
  useEffect(() => {
    if (companyList.length > 0) {
      setCurrentCompany(companyList[0]);
    }
  }, [companyList]);

  // Quando o usuário autentica (no início) ele atualiza a lista de empresas
  useEffect(() => {
    if (userAttributes !== null) {
      listCompanies();
    }
    // eslint-disable-next-line
  }, [userAttributes]);

  //////////////////////////////////////////
  // Function Section
  //////////////////////////////////////////

  const handleChangeMonth = async function (aEvent) {
    setSelectedMonth(aEvent.target.value);
  };

  const handleChangeYear = async function (aEvent) {
    setSelectedYear(aEvent.target.value);
  };

  // busca o usuário autenticado no cognito
  const updateUser = async () => {
    try {
      const attributes = await fetchUserAttributes();
      setUserAttributes(attributes);
    } catch (error) {
      console.log(error);
      console.log('Não está autenticado');
    }
  };

  const handleChangeCompany = function (aEvent) {
    const id = aEvent.target.value;
    for (const company of companyList) {
      if (company.id === id) {
        setCurrentCompany(company);
      }
    }
  };

  const listCompanies = async function () {
    try {
      setLoading(true);
      const ids = JSON.parse(userAttributes.family_name);
      const ops = [];
      for (const id of ids) {
        const result = (
          await apiClient.graphql({
            query: customQueries.getBroadcaster,
            variables: {
              id: id,
            },
          })
        ).data.getBroadcaster;

        if (result) {
          ops.push(result);
        }
      }
      setcompanyList(ops);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  function removeMetadata(aFile) {
    const fileName = aFile.Key.split('/').at(-1);
    const fileExtension = fileName.split('.').at(-1);
    return fileExtension !== 'metadata';
  }

  const loadFiles = async function () {
    try {
      setLoading(true);

      const apiName = 'lambdasApi';
      const path = '/listGatewayReports';
      const options = {
        body: {
          companyId: currentCompany.id.toString(),
          month: selectedMonth.toString().padStart(2, '0'),
          year: selectedYear.toString(),
        },
        headers: {},
      };
      const operation = post({
        apiName: apiName,
        path: path,
        options: options,
      });
      const { body } = await operation.response;
      const payload = await body.json();
      setFileList(payload.filter(removeMetadata));
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const listOperators = async function (aUrn) {
    const response = await fetch(
      `https://20arn6e6dj.execute-api.us-east-1.amazonaws.com/production/listoperators2?resource_id=${aUrn}`
    );
    const body = await response.json();
    if (body.errors) {
      throw new Error(JSON.stringify(body.errors));
    }
    return body;
  };

  const getCount = function (aId, aData) {
    for (const obj of aData) {
      if (obj.operator === aId) {
        return obj.count;
      }
    }
    return 0;
  };

  const removeDtvGo = function (item) {
    return !item.id.includes('directvgo');
  };

  /**
   * Recebe um CSV (no formato arrayBuffer) com os ISPs que tiveram acessos ao SVA no mes.
   * Adiciona os que não tiveram acesso e devolve no formato arrayBuffer
   * @param {*} aArrayBuffer
   * @returns
   */
  const addIsps = async function (aArrayBuffer, aIspList, aUrn) {
    const decoder = new TextDecoder();
    const encoder = new TextEncoder();

    // converte para um objeto JSON
    const str = decoder.decode(aArrayBuffer);
    const dataJson = csvJSON(str.replaceAll('"', ''));

    const isoDate = `${selectedYear}-${selectedMonth
      .toString()
      .padStart(2, '0')}-05T14:40:00.000Z`;
    const d = new Date(isoDate).toLocaleString('pt-BR', {
      month: 'long',
      year: 'numeric',
    });

    let csv = `${aUrn}\n\nid, operador, data, acessos\n`;
    for (const isp of aIspList) {
      const accessCount = getCount(isp.id, dataJson);
      csv += `${isp.id}, ${isp.name}, ${d}, ${accessCount}\n`;
    }

    // remove os acentos e codifica
    const encodedString = encoder.encode(
      csv.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    );

    return encodedString;
  };

  const csvJSON = function (aCsv) {
    const lines = aCsv.split('\n');

    const result = [];

    const headers = lines[0].split(',');

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(',');

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    return result; //JavaScript object
    //    return JSON.stringify(result); //JSON
  };

  const handleDownload = async function (aFile) {
    try {
      if (window.document.documentMode) {
        // Do IE stuff
        throw new Error(
          'Download não funciona em modo Internet Explorer. Use outro navegador'
        );
      }

      setLoading(true);

      const wscols = [
        { wch: 40 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      // busca os dados de acesso e retorna em formato arrayBuffer
      const data = await (await fetch(aFile.url)).arrayBuffer();

      const isps = (await listOperators(aFile.urn)).filter(removeDtvGo);

      // adiciona todos os ISPs com o produto habilitado, mesmo os sem acessos no mes
      const dataFull = await addIsps(data, isps, aFile.urn);

      // put the contents in a SheetjS Workbook
      const workBook = read(dataFull);

      if (workBook !== null) {
        // altera a largura das colunas
        const worksheetNames = workBook.SheetNames;
        for (const name of worksheetNames) {
          const workSheet = workBook.Sheets[name];
          workSheet['!cols'] = wscols;
          workBook.Sheets[name] = workSheet;
        }

        const fileName = `${aFile.urn} ${selectedMonth
          .toString()
          .padStart(2, '0')}-${selectedYear.toString()}.xlsx`;
        writeFile(workBook, fileName);
      }
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <Fragment>
      <Box
        sx={{
          display: companyList.length > 0 ? 'block' : 'none',
          padding: '32px',
        }}
      >
        <Loading loading={Boolean(loading)} />

        <Typography>
          Relatórios de utilização de serviços digitais no Gateway Neo
        </Typography>

        {/* Mensagem de erro */}
        <Snackbar
          open={Boolean(errorAlert)}
          autoHideDuration={2500}
          onClose={() => setErrorAlert(null)}
        >
          <Alert
            onClose={() => setErrorAlert(null)}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errorAlert}
          </Alert>
        </Snackbar>

        {/* Mensagem de sucesso */}
        <Snackbar
          open={Boolean(successAlert)}
          autoHideDuration={1500}
          onClose={() => setSuccessAlert(null)}
        >
          <Alert
            onClose={() => setSuccessAlert(null)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {successAlert}
          </Alert>
        </Snackbar>

        <Box sx={styles.container}>
          <FormControl variant="standard" sx={{ minWidth: '220px' }}>
            <InputLabel>Selecione o serviço digital</InputLabel>
            <Select
              name="layout"
              value={currentCompany.id}
              onChange={(e) => handleChangeCompany(e)}
              variant="standard"
            >
              {companyList.map((item, idx) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.trade}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard">
            <InputLabel>Mês</InputLabel>
            <Select
              name="mes"
              value={selectedMonth}
              onChange={(e) => handleChangeMonth(e)}
              variant="standard"
            >
              {[...Array(12).keys()].map((item, idx) => (
                <MenuItem key={idx} value={idx + 1}>
                  {(idx + 1).toString().padStart(2, '0')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard">
            <InputLabel>Ano</InputLabel>
            <Select
              name="ano"
              value={selectedYear}
              onChange={(e) => handleChangeYear(e)}
              variant="standard"
            >
              {yearsArray.map((year, idx) => (
                <MenuItem key={idx} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            endIcon={<Search />}
            onClick={() => loadFiles()}
          >
            Buscar
          </Button>
        </Box>

        <Box sx={styles.fileContainer}>
          {fileList.length === 0 && (
            <Typography>Não há arquivos disponíveis.</Typography>
          )}

          {fileList.map((file, idx) => (
            <Box sx={styles.file} key={file.Key}>
              <Box sx={styles.smallContainer}>
                {/* <Typography variant="caption" gutterBottom sx={{ flexGrow: 2 }}>
                  {file.Key.split('/').at(-1)}
                </Typography> */}

                <Typography variant="caption" gutterBottom>
                  {file.urn}
                </Typography>

                <Typography variant="caption" gutterBottom>
                  {new Date(file.LastModified).toLocaleString()}
                </Typography>

                <Typography variant="caption" gutterBottom>
                  <p>{formatBytes(file.Size)}</p>
                </Typography>

                <Tooltip title="Download">
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() =>
                        //                        handleDownload(file.url, file.Key.split('/').at(-1))
                        handleDownload(file)
                      }
                      edge="end"
                      sx={{
                        color: 'white',
                        backgroundColor: green[500],
                        marginLeft: 'auto',
                        height: 'fit-content',
                      }}
                    >
                      <Download />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Fragment>
  );
};

export default PartnerBaseReports;
