export const getOperator = /* GraphQL */ `
  query ($id: ID!, $nextTokenRegions: String) {
    getOperator(id: $id) {
      id
      trade
      company_name
      corporate_registry
      disabled
      headend
      evolution
      gatewayId
      econoGroup
      externalDisabled
      regions(limit: 2000, nextToken: $nextTokenRegions) {
        items {
          id
          technology
          disabled
          municipality {
            id
            code
            name
            state
          }
        }
        nextToken
      }
    }
  }
`;

export const getOperatorShort = /* GraphQL */ `
  query ($id: ID!) {
    getOperator(id: $id) {
      id
      trade
      company_name
      corporate_registry
      disabled
      externalDisabled
    }
  }
`;

export const getBroadcaster = /* GraphQL */ `
  query ($id: ID!, $nextToken: String) {
    getBroadcaster(id: $id) {
      id
      trade
      company_name
      corporate_registry
      disabled
      svas(nextToken: $nextToken) {
        items {
          id
          name
          disabled
          disabledDate
          urn
        }
        nextToken
      }
    }
  }
`;
